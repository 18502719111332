import { useSettings } from '@backpackjs/storefront';

export default function HomeTemplate({ page, renderSections }) {
  const settings = useSettings();
  const { enableTransparentBackground: enable } = { ...settings?.header };
  return (
    <div className={`${enable ? '-mt-[73px]' : ''}`}>{renderSections()}</div>
  );
}

HomeTemplate.displayName = 'HomeTemplate';
